let runtimeConfig = null;

const fetch = async (url, opt = {}) => {
  if (!runtimeConfig) runtimeConfig = useRuntimeConfig();
  const { bypassAuth } = opt;
  const token = useCookie("token"); // User token
  if (!token.value && !bypassAuth) return navigateTo("/login"); // If no token, redirect to login
  if (url.includes(":accountId")) {
    const { $account } = useNuxtApp();
    url = url.replace(":accountId", $account.id);
  } else if (url.includes(":printerId")) {
    const { $printer } = useNuxtApp();
    url = url.replace(":printerId", $printer.id);
  }
  return new Promise(async (resolve, reject) => {
    const res = await $fetch(url, {
      ...opt,
      baseURL: runtimeConfig.public.apiBase, // API base URL
      headers: {
        ...opt.headers,
        "Authorization": bypassAuth ? "" : `Bearer ${ token.value }` // Add token to headers
      }
    }).then(res => {
      resolve({
        ...res
      });
    }).catch((e) => {
      if (!e.response) {
        const toast = useToast();
        toast.add({
          id: "global-error",
          title: "Une erreur global est survenue",
          description: "Veuillez réessayer plus tard",
          icon: "i-heroicons-x-circle",
          color: "red",
          timeout: 5000
        });
        return reject(e);
      }
      if (e.response?._data?.code === "INVALID_CAPTCHA") {
        const toast = useToast();
        toast.add({
          id: "global-error",
          title: "CAPTCHA invalide",
          description: "Vous ne pouvez pas effectuer cette action",
          icon: "i-heroicons-x-circle",
          color: "red",
          timeout: 10000
        });
        return reject(false);
      } else if (e.response?._data?.code === "UNAUTHORIZED") {
        const toast = useToast();
        toast.add({
          id: "global-error",
          title: "Non autorisé",
          description: "Vous n'êtes pas autorisé à effectuer cette action",
          icon: "i-heroicons-x-circle",
          color: "red",
          timeout: 5000
        });
        return reject(false);
      }
      reject(e.response._data);
    });
  });
};

const urlBuilder = (url) => {
  if (url.includes(":accountId")) {
    const { $account } = useNuxtApp();
    url = url.replace(":accountId", $account.id);
  }
  return `${ runtimeConfig.public.apiBase }${ url }`;
};

const getPage = (array, page, max = 20) => {
  if (page === 1) return array.slice(0, max);
  return array.slice((page - 1) * max, page * max);
};

const base64ToFile = (data, filename) => {
  const arr = data.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);
  return new File([u8arr], filename, {
    type: mime
  });
};


const getLength = (array, max) => {
  return Math.ceil(array.length / max);
};

const getCustomerDisplayname = (customer) => {
  let displayname = "";
  displayname += customer.company ? `${ customer.company } ` : "";
  if (customer.company && (customer.firstname || customer.lastname)) displayname += "(";
  displayname += customer.firstname ? `${ customer.firstname } ` : "";
  displayname += customer.lastname ? `${ customer.lastname } ` : "";
  if (displayname.endsWith(" ")) displayname = displayname.slice(0, -1);
  if (customer.company && (customer.firstname || customer.lastname)) displayname += ")";
  if (displayname.endsWith(" ")) displayname = displayname.slice(0, -1);
  return displayname;
};


const MONTHS_LONG = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
const MONTHS_SHORT = ["Jan.", "Fév.", "Mar.", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sep.", "Oct.", "Nov.", "Déc."];
const DAYS_LONG = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
const DAYS_SHORT = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."];
const DAYS_SHORT_LOWER = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."];

const pad = (n) => (n < 10 ? "0" + n : n);

/**
 * Format DATE
 * @param {Date} date
 * @param {String} format
 * @return {String}
 */
const formatDate = (date, format = "dd/mm/YYYY HH:ii:ss") => {
  if (!(date instanceof Date)) date = new Date(date);

  const replacements = {
    YYYY: date.getFullYear(),
    MMMM: MONTHS_LONG[date.getMonth()],
    MMM: MONTHS_SHORT[date.getMonth()],
    MM: pad(date.getMonth() + 1),
    dddd: DAYS_LONG[date.getDay()],
    DDD: DAYS_SHORT[date.getDay()],
    ddd: DAYS_SHORT_LOWER[date.getDay()],
    dd: pad(date.getDate()),
    HH: pad(date.getHours()),
    hh: pad(date.getHours() % 12 || 12),
    ii: pad(date.getMinutes()),
    ss: pad(date.getSeconds()),
    SSS: date.getMilliseconds().toString().padStart(3, "0")
  };

  const escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  const literals = [];


  format = format.replace(/'([^']*)'/g, (_, literal) => {
    literals.push(literal);
    return "\0";
  });

  Object.entries(replacements).forEach(([key, value]) => {
    format = format.replace(new RegExp(escapeRegex(key), "g"), value);
  });

  return format.replace(/\0/g, () => literals.shift());
};

const redirectToPayments = () => {
  const toast = useToast();
  toast.add({
    id: "account",
    title: "Vous n'avez pas d'abonnement",
    description: "Vous n'avez pas d'abonnement, vous ne pouvez pas accéder à cette page",
    icon: "i-heroicons-information-circle",
    color: "red",
    timeout: 3000
  });
  navigateTo("/subscriptions");
};

const logout = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  window.location.href = "/login";
};

const toast = {
  success: (title, description) => {
    const toast = useToast();
    toast.add({
      id: "success",
      title,
      description,
      icon: "i-heroicons-check-circle",
      color: "green",
      timeout: 5000
    });
  }
};

const url2base64 = async (url) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

const getNumberByQuantity = (quantity, json) => {
  if (!json) return 0;
  if (typeof json === "string" || typeof json === "number") return +json;
  const keys = Object.keys(json).map(Number).sort((a, b) => a + b);
  let last = keys[0];
  for (const key of keys) {
    if (+quantity < +key) return +json[last];
    last = key;
  }
  return +json[last];
};
export { getNumberByQuantity };

const capitalize = (string) => {
  // Capitalize first letter, and orther after _, -, space + remove _ and -
  return string.replace(/[_-]/g, " ").replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}
export { capitalize };

export {
  fetch,
  getPage,
  getLength,
  formatDate,
  base64ToFile,
  getCustomerDisplayname,
  redirectToPayments,
  logout,
  toast,
  urlBuilder,
  url2base64
};
