<template>
	<div>
		<UModal v-model="isOpen" prevent-close v-auto-animate>
			<div>
				<div class="p-8 grid gap-6" v-if="loading">
					<div class="flex justify-center items-center flex-col">
						<VsLoading color="primary" type="corners" style="position: relative"/>
						<p class="p-4 text-center text-primary">{{ content || 'Chargement en cours...' }}</p>
					</div>
				</div>
				<div class="p-8 grid gap-6" v-else style="z-index: 9999">
					<div>
						<div class="flex justify-end">
							<i class="isax isax-close-circle" @click="close"/>
						</div>
						<h3>{{ title }}</h3>
						<p class="little-text pt-2">{{ content }}</p>
					</div>
					<div class="input" v-if="input && input.type === 'select'">
						<c-select v-model="input.value" :label="input.label" :options="input.options" ref="modalInput"/>
					</div>
					<div class="input" v-else-if="input">
						<c-input v-model="input.value" :label="input.label" :type="input.type" ref="modalInput"/>
					</div>
					<div v-else-if="html">
						<div v-html="html"/>
					</div>
					<div v-if="pdf">
						<!-- PDF is a base64 -->
						<iframe :src="'data:application/pdf;base64,'+pdf" width="100%" height="500px"/>
					</div>
					<div class="flex justify-end">
						<div class="grid grid-cols-2 gap-4">
							<VsButton class="button__primary h-fit justify-center border border-gray-300" color="transparant"
							          @click="cancel" v-if="!hideCancelButton">
								<p class="p-1 text-sm text-gray-800">{{ cancelButtonText }}</p>
							</VsButton>
							<div v-else></div>
							<VsButton class="button__primary h-fit" :color="confirmButtonColor" @click="confirm"
							          :loading="loadings.includes('confirm')">
								<p class="p-1 text-sm">{{ confirmButtonText }}</p>
							</VsButton>
						</div>
					</div>
				</div>
			</div>
		</UModal>
	</div>
</template>

<script>
import { VsButton, VsLoading } from "vuesax-ts";

export default {
	name: "modal",
	components: {
		VsButton,
		VsLoading
	},
	data() {
		return {
			isOpen: false,
			title: "Êtes-vous sûr de valider ?",
			content: "Vous ne pourrez plus revenir en arrière.",
			confirmButtonText: "Continuer",
			cancelButtonText: "Annuler",
			loadings: [],
			loading: false,
			input: {
				label: "Input",
				type: "text",
				value: "",
				options: []
			},
			inputOptions: {},
			inputType: "text",
			confirmButtonColor: "success",
			inputValue: "",
			inputValidator: null,
			inputAttributes: {},
			hideCancelButton: false,
			html: null,
			pdf: null,
			preConfirm: null,
			closeOnSuccess: true,
		};
	},
	mounted() {
		window.addEventListener("keyup", (e) => {
			if (e.key === "Escape") {
				this.close();
			}
		});
		window.addEventListener("click", (e) => {
			if (e.target.classList.contains("modal")) {
				this.close();
			}
		});
	},
	methods: {
		fire(data = {}) {
			this.isOpen = true;
			this.title = data.title ?? "Êtes-vous sûr de valider ?";
			this.content = data.content ? data.content : data.text ? data.text : data.html ? null : "Vous ne pourrez plus revenir en arrière.";
			this.confirmButtonText = data.confirmButtonText ?? "Continuer";
			this.cancelButtonText = data.cancelButtonText ?? "Annuler";
			this.input = data.input ?? null;
			this.html = data.html ?? null;
			this.preConfirm = data.preConfirm ?? null;
			this.didOpen = data.didOpen ?? null;
			this.loading = data.loading ?? false;
			this.confirmButtonColor = data.confirmButtonColor ?? "success";
			this.hideCancelButton = data.hideCancelButton ?? false;
			this.closeOnSuccess = data.closeOnSuccess ?? true;
			this.pdf = data.pdf ?? null;
			this.loadings = [];

			this.didOpen?.();
			return new Promise(resolve => {
				this.resolve = resolve;
			});
		},
		async confirm() {
			if (this.preConfirm) {
				this.loadings.push("confirm");
				const isConfirmed = (await this.preConfirm(this.input?.value)) ?? true;
				if (isConfirmed) {
					this.resolve({
						isConfirmed: true,
						cancel: false
					});
					if (!this.closeOnSuccess) this.isOpen = false;
				} else this.loadings = this.loadings.filter(l => l !== "confirm");
			} else {
				this.resolve({
					isConfirmed: true,
					cancel: false,
					value: this.input?.value
				});
				this.isOpen = false;
			}
		},
		hideLoading() {
			this.loading = false;
		},
		isLoading() {
			return this.loading;
		},
		showValidationMessage(message) {
			this.$refs.modalInput.errors.push(message);
		},
		cancel() {
			this.resolve({
				isConfirmed: false,
				cancel: true
			});
			this.isOpen = false;
		},
		close() {
			this.resolve({
				isConfirmed: false,
				dismiss: true
			});
			this.isOpen = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "assets/scss/components/input.scss";
.isax {
	cursor: pointer;
	transition: all 0.2s ease;
	&:hover {
		color: $red;
	}
}
* {
	z-index: 5
}
.input {
	z-index: 6;
}
</style>
