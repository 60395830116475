let socket;
export default defineNuxtPlugin({
	name: "sockets",
	setup() {
		const ctx = useNuxtApp()
		return {
			provide: {
				sockets: {
					set: (opt = {}) => {
						socket = ctx.$nuxtSocket(opt);
					},
					on: (event, callback) => {
						socket.on(event, callback);
					},
					emit: (event, data) => {
						socket.emit(event, {
							...data
						});
					},
					off: (event, callback) => {
						socket.off(event, callback);
					},
					get: () => {
						return socket;
					}
				}
			}
		};
	}
});
