<style lang="scss" scoped>
@import "assets/scss/components/input.scss";
.cancel {
	position: absolute;
	right: 8px;
	top: calc(50% - 12px);
	color: $red;
	cursor: pointer !important;
	opacity: 1 !important;
	transition: all 0.2s ease;
}
.tooltip {
	position: absolute;
	right: 8px;
	top: calc(50% - 12px);
	cursor: pointer !important;
	opacity: 1 !important;
	transition: all 0.2s ease;
}
</style>

<template>
	<div class="input" v-auto-animate :class="{
		'required': validation.includes('required'),
		'disabled': disable,
		'error': errors.length > 0,
		'isFocus': isFocus,
	}" @click="focusInput">

		<div class="content" v-if="type !== 'file'">
			<input class="content__field" :placeholder="placeholder ?? label" ref="input"
			       :name="id" :type="type"
			       :min="type === 'number' ? 0 : ''"
			       v-model="current" :disabled="disable" :data-form-type="dataFormType"
			       @focusin="isFocus = true"
			       @focusout="isFocus = false"
			       @input="this.$emit('update:current', $event.target.value)"
			       :class="{
					   'error': errors.length > 0,
					   'uppercase': upperCase,
			       }">
			<label class="content__label" :for="id">{{ label || 'Label non défini' }}</label>
			<i v-if="disable && canCancel" class="isax isax-close-circle cancel" @click="handleCancel"></i>
			<div v-if="tooltip" class="tooltip">
				<UTooltip :text="tooltip" :popper="{ placement: 'top' }">
					<i class="isax isax-info-circle"></i>
				</UTooltip>
			</div>
		</div>


		<!-- Input type file -->
		<div class="content" :class="errors.length > 0 ? 'error' : ''" v-else>
			<input :id="id" :placeholder="placeholder ?? label" class="content__field" :name="id" :type="type"
			       :accept="accept" :hidden="hidden && preview" @input="checkInput" :disabled="disable"
			       @change="handleFileChange">
			<label class="content__label" :for="id">{{ label }}
				<span>{{ modelValue.size ? bytesToSize(modelValue.size) : bytesToSize(0) }} / {{
						bytesToSize(maxSize)
					}}</span></label>
			<i v-if="modelValue.base64 && !preview" class="isax isax-close-circle cancel" @click="handleCancel"></i>
			<div class="content__placeholder" @click="handleClick">
				<img v-if="preview && modelValue.base64"
				     :src="modelValue.base64 ?? ''">
			</div>
		</div>
		<!-- Show only first error -->
		<div v-if="errors.length > 0 && !disable" class="input__error">
			{{ errors[0] }}
		</div>
	</div>
</template>
<script>
import { defineExpose } from "vue";
export default {
	name: "c-input",
	props: {
		id: {
			type: String,
			required: false
		},
		label: {
			type: String,
			default: ""
		},
		dataFormType: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		upperCase: {
			type: Boolean,
			default: false
		},
		tooltip: {
			type: String,
		},
		preview: {
			type: Boolean,
			default: true
		},
		maxSize: {
			type: Number,
			// 16Mo
			default: 16777216
		},
		validation: {
			type: String,
			default: ""
		},
		accept: {
			type: String,
			default: ""
		},
		canCancel: {
			type: Boolean,
			default: false
		},
		hidden: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: "text"
		},
		modelValue: { // String or number
			type: [String, Number, Object],
			default: ""
		},
		disable: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			errors: [],
			backgroundImage: null,
			isFocus: false,
			current: this.modelValue
		};
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			},
		},
	},
	methods: {
		handleCancel() {
			this.$emit("update:modelValue", "");
			this.backgroundImage = null;
			this.$el.querySelector("input").value = "";
		},
		focusInput() {
			this.$el.querySelector("input").focus();
		},
		handleFileChange() {
			const input = this.$refs.input;
			const file = input.files[0];
			const reader = new FileReader();
			reader.onload = (e) => {
				this.backgroundImage = e.target.result;
				this.$emit("update:modelValue", {
					name: file.name,
					size: file.size,
					type: file.type,
					base64: e.target.result,
					lastModified: file.lastModified,
					lastModifiedDate: file.lastModifiedDate,
				});
			};
			reader.readAsDataURL(file);
			if (file.size > this.maxSize) {
				this.errors.push("Le fichier est trop volumineux");
				input.classList.add("error");
			} else {
				input.classList.remove("error");
			}
		},
		handleClick() {
			const input = this.$el.querySelector("input");
			input.click();
		},
		getType() {
			return "input";
		},
		checkInput(silence = false) {
			const input = this.$refs.input;
			const value = this.current;
			if (this.validation.length === 0) return true;
			else if (!this.validation.includes("required") && value.length === 0) return true;
			const messages = {
				required: "Ce champ est requis",
				length: "Ce champ doit contenir entre {min} et {max} caractères",
				min: "Ce champ doit contenir au moins {min} caractères",
				max: "Ce champ doit contenir au maximum {max} caractères",
				matches: "Ce champ n'est pas valide",
				email: "Ce champ n'est pas un email valide",
				same: "Ce champ doit être identique",
				phone: "Ce champ n'est pas un numéro de téléphone valide",
				url: "Ce champ n'est pas une URL valide",
			};
			const validation = this.validation.split("|");
			if (!silence) this.errors = [];
			let pass = true;
			validation.forEach((rule) => {
				let [name, ...params] = rule.split(":");
				params = params.join(":");
				const isValid = this[name](value, params);
				if (!isValid) {
					if (name === "max" || "min") messages[name] = messages[name].replace("{min}", params).replace("{max}", params);
					if (!silence) this.errors.push(messages[name]);
					else {
						console.log(messages[name]);
					}
					pass = false;
				}
			});
			if (this.validation.length > 0 && (silence && !pass)) {
				input?.classList.add("error");
			} else {
				input?.classList.remove("error");
			}
			return silence ? pass : this.errors.length === 0;
		},
		required(value) {
			return value !== "";
		},
		min(value, min) {
			return value.length >= min;
		},
		url(value) {
			return new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/).test(value);
		},
		max(value, max) {
			return value.length <= max;
		},
		length(value, min, max) {
			return value.length >= min && value.length <= max;
		},
		matches(value, regex) {
			return new RegExp(regex).test(value);
		},
		email(value) {
			return new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/).test(value);
		},
		same(value, idValue2) {
			return document.getElementById(idValue2) ? value === document.getElementById(idValue2).value : true;
		},
		phone(value) {
			return new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/).test(value);
		},
	},
	watch: {
		modelValue(newVal) {
			this.current = newVal;
		},
		current() {
			this.checkInput();
			this.$emit("update:modelValue", this.current);
		}
	},
	mounted() {
		defineExpose({
			checkInput: this.checkInput
		});
	}
};
</script>
