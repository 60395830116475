<style lang="scss" scoped>
@import "assets/scss/components/input.scss";
.content {
	cursor: pointer;
}
.select {
	border: 1px solid #cfd1d5;
	border-radius: 0 0 6px 6px;
	border-top: none;
	padding: 6px;
	background: $white;
	overflow: auto;
	max-height: 300px;
	top: calc(100% + 0px);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
	display: grid;
	grid-gap: 2px;
	z-index: 6;
	li {
		font-size: 0.825rem;
		cursor: pointer;
		padding: 6px 10px;
		color: $light-color;
		transition: all 0.25s ease;
		border-radius: 8px;
		align-items: center;
		display: flex;
		img {
			width: 20px;
			border-radius: 4px;
			margin-right: 8px;
		}
		&:hover {
			padding-left: 14px;
			color: $blue;
		}
	}
}
</style>

<template>
	<div class="input" :class="{
		'required': validation.includes('required'),
		'disabled': disable
	}" @click="showDropdown = !showDropdown">
		<div class="content" :class="{
			'error': errors.length > 0,
			'focus': showDropdown
		}" v-auto-animate="{ duration: 150 }">
			<input :id="id" :placeholder="placeholder ?? label" class="content__field" :name="id"
			       @focus="showDropdown = true"
			       style="pointer-events: none;"
			       :value="!countries ? options.find(option => option.value === value)?.label || value : data?.countries?.find(country => country.code === value)?.name"
			       @input="triggerInput" :data-form-type="dataFormType" :disabled="disable" :class="{
					   'error': errors.length > 0,
			       }">
			<label class="content__label" :for="id">{{ label }}</label>
			<div class="select absolute w-100" v-if="showDropdown">
				<ul class="grid">
					<!-- data.countries[0].flag is a base64 of the country fkag -->
					<UDivider v-if="countries" class="pb-2" label="Pays courants"/>
					<li v-if="countries" v-for="country in data.common" @click="value = country.code"><img
						:src="`data:image/png;base64,${ country.flag }`" alt="">{{ country.name }}
					</li>
					<UDivider v-if="countries" class="py-2" label="Autres pays"/>
					<li v-if="countries" v-for="country in data.countries" @click="value = country.code"><img
						:src="`data:image/png;base64,${ country.flag }`" alt="">{{ country.name }}
					</li>
					<li v-if="!countries" v-for="option in options.filter(o => typeof o === 'object')"
					    @click="value = option.value">{{ option.label || option.value }}
					</li>
					<!-- TODO: Opt group -->
				</ul>
			</div>
		</div>
		<!-- Show only first error -->
		<div v-if="errors.length > 0 && !disable" class="input__error">
			{{ errors[0] }}
		</div>
	</div>
</template>
<script>
import { fetch } from "~/utils/helper";

export default {
	name: "c-select",
	props: {
		id: {
			type: String,
			required: false
		},
		label: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			required: false,
		},
		countries: {
			type: Boolean,
		},
		placeholder: {
			type: String,
			default: ""
		},
		validation: {
			type: String,
			default: ""
		},
		modelValue: { // String or number
			type: [String, Number, null],
			default: ""
		},
		disable: {
			type: Boolean,
			default: false
		},
		dataFormType: {
			type: String,
		}
	},
	data() {
		return {
			errors: [],
			showDropdown: false,
			showedValue: "",
			data: {
				countries: null,
				common: null
			}
		};
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			},
		},
	},
	mounted() {
		if (this.countries) {
			fetch("/countries", {
				bypassAuth: true
			}).then((data) => {
				this.data.countries = data.countries;
				this.data.common = data.common;
			});
		}
		window.addEventListener("click", (e) => {
			if (e.target !== this.$el && !this.$el.contains(e.target)) {
				this.showDropdown = false;
			}
		});
	},
	methods: {
		triggerInput(e) {
			// Find option with label who contains input value
			const value = e.target.value.toLowerCase();
			let option;
			if (this.countries) option = this.data.countries.find(country => country.name.toLowerCase().includes(value));
			else option = this.options.find(option => option.label.toLowerCase().includes(value));

			if (option) this.$emit("update:modelValue", option.value || option.code);
			else this.$emit("update:modelValue", null);
		},
		checkInput() {
			const value = this.value;
			if (this.validation.length === 0) return true;
			const messages = {
				required: "Ce champ est requis",
			};
			const validation = this.validation.split("|");
			this.errors = [];
			validation.forEach((rule) => {
				let [name, ...params] = rule.split(":");
				params = params.join(":");
				const isValid = this[name](value, params);
				if (!isValid) {
					if (name === "max" || "min") messages[name] = messages[name]?.replace("{min}", params[0])?.replace("{max}", params[0]);
					this.errors.push(messages[name]);
				}
			});
			return this.errors.length === 0;
		},
		required(value) {
			return value !== "";
		}
	},
	watch: {
		modelValue(newVal, oldVal) {
			this.checkInput();
		},
		options(newVal, oldVal) {
			// Si la value actuel n'est pas dans les options, on la reset
			if (newVal.length > 0 && !newVal.find(option => option.value === this.value)) {
				this.value = newVal[0].value;
			}
		}
	}
};
</script>
