import revive_payload_client_vj7iVBKLdE from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0WJj6aTrMn from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QggJDxljC1 from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_E14aFFJGtI from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tvlyCmlkfR from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8awtCMD6G6 from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_yeyAzNEekK from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_63veAS33rF from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_MpMZ5oSE2k from "/app/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import i18n_bcbFMWiipB from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.20.0_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_3R9pIL8sfL from "/app/node_modules/.pnpm/@nuxt+ui@2.18.3_rollup@4.20.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_0rFKRWqyQv from "/app/node_modules/.pnpm/@nuxt+ui@2.18.3_rollup@4.20.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_LrqxW0el2Z from "/app/node_modules/.pnpm/@nuxt+ui@2.18.3_rollup@4.20.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_6cDWIIzeS6 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_sPvm6CXpv1 from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_rollup@4.20.0_vite@5.3.5_vue@3.4.35/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_zoF4yPIBkC from "/app/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import google_recaptcha_rYK9Kziv68 from "/app/plugins/google-recaptcha.ts";
import konva_7dtTaXju5v from "/app/plugins/konva.js";
import mitt_ZNXaeqsgM5 from "/app/plugins/mitt.js";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import sockets_tf4I3c66A4 from "/app/plugins/sockets.js";
import vuesax_IhK6CckThS from "/app/plugins/vuesax.js";
export default [
  revive_payload_client_vj7iVBKLdE,
  unhead_0WJj6aTrMn,
  router_QggJDxljC1,
  payload_client_E14aFFJGtI,
  navigation_repaint_client_tvlyCmlkfR,
  check_outdated_build_client_8awtCMD6G6,
  view_transitions_client_yeyAzNEekK,
  chunk_reload_client_63veAS33rF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MpMZ5oSE2k,
  plugin_6Ph1iQzNHk,
  i18n_bcbFMWiipB,
  slideovers_3R9pIL8sfL,
  modals_0rFKRWqyQv,
  colors_LrqxW0el2Z,
  plugin_client_6cDWIIzeS6,
  plugin_sPvm6CXpv1,
  plugin_zoF4yPIBkC,
  google_recaptcha_rYK9Kziv68,
  konva_7dtTaXju5v,
  mitt_ZNXaeqsgM5,
  sentry_3AyO8nEfhE,
  sockets_tf4I3c66A4,
  vuesax_IhK6CckThS
]