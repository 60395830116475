<template>
  <div>
    <NuxtLayout>
      <NuxtPage :transition="{
        name: 'page',
        mode: 'out-in'
      }" />
    </NuxtLayout>
    <UNotifications />
    <Modal ref="modal"></Modal>
  </div>
</template>

<script setup lang="ts">
useHead({
  title: "Printags",
  script: [{
    src: "/libraries/webfont.js",
  }]
});

const nuxtApp = useNuxtApp();
const { $sockets } = nuxtApp;
$sockets.set({
  name: "main",
  closeOnBeforeunload: false,
  withCredentials: true
});
$sockets.on("connected", () => {
  //console.log(`[SOCKET] Soft connected to ${ $sockets.get().io.uri } from ${ $route.path + "/" }`);
});
$sockets.on("disconnect", () => {
  console.log(`[SOCKET] Disconnected from ${ $sockets.get().io.uri }`);
  // try to reconnect
  if ($sockets.get().connectionTimeout) {
    clearTimeout($sockets.get().connectionTimeout);
  }
  $sockets.get().connectionTimeout = setTimeout(() => {
    console.log(`[SOCKET] Trying to reconnect to ${ $sockets.get().io.uri }`);
    if (!$sockets.get().initiated) $sockets.get().connect();
    else clearTimeout($sockets.get().connectionTimeout);
  }, 100);
});
const modal = ref(null);
onMounted(() => {
  if (!nuxtApp.$swal) nuxtApp.provide("swal", modal.value);
});
</script>

<script lang="ts">

export default {
  name: "Index"
};

</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
