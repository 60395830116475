import { default as indexFXjYqPb3WZMeta } from "/app/pages/customers/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as indexid17FJYqCuMeta } from "/app/pages/developers/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_937Kbaa5vEkyMeta } from "/app/pages/invite/[id].vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as accountshD8HoADkrKMeta } from "/app/pages/management/accounts.vue?macro=true";
import { default as configiGKqTwGmdXMeta } from "/app/pages/management/config.vue?macro=true";
import { default as indexzNIwQQYXvHMeta } from "/app/pages/management/index.vue?macro=true";
import { default as orders3T9MkpUY9MMeta } from "/app/pages/management/orders.vue?macro=true";
import { default as createnzDtnXXm8IMeta } from "/app/pages/orders/create.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as _91id_93vZXVq7IOTKMeta } from "/app/pages/password-reset/[id].vue?macro=true";
import { default as indexYgRHYxGzjsMeta } from "/app/pages/password-reset/index.vue?macro=true";
import { default as index4DIviTw3fSMeta } from "/app/pages/payments/index.vue?macro=true";
import { default as indexqHCPMm9Gq1Meta } from "/app/pages/printers/index.vue?macro=true";
import { default as productionsBX6pYq43xrMeta } from "/app/pages/printers/productions.vue?macro=true";
import { default as stocksWvVXqp03MBMeta } from "/app/pages/printers/stocks.vue?macro=true";
import { default as transactionswZqDIN4G6rMeta } from "/app/pages/printers/transactions.vue?macro=true";
import { default as _91id_938d7mq4Zm2kMeta } from "/app/pages/register/[id].vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as accountFbmhd9M064Meta } from "/app/pages/settings/account.vue?macro=true";
import { default as billingu0Xntxr74XMeta } from "/app/pages/settings/billing.vue?macro=true";
import { default as userCkZPhnbvCdMeta } from "/app/pages/settings/user.vue?macro=true";
import { default as indexzqmHNbk6IKMeta } from "/app/pages/stocks/index.vue?macro=true";
import { default as indexrOL1EKYmM2Meta } from "/app/pages/subscriptions/index.vue?macro=true";
import { default as indexH6dMEPckhVMeta } from "/app/pages/team/index.vue?macro=true";
export default [
  {
    name: "customers___en",
    path: "/en/customers",
    component: () => import("/app/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers___fr",
    path: "/customers",
    component: () => import("/app/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___fr",
    path: "/dashboard",
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "developers___en",
    path: "/en/developers",
    component: () => import("/app/pages/developers/index.vue").then(m => m.default || m)
  },
  {
    name: "developers___fr",
    path: "/developers",
    component: () => import("/app/pages/developers/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-id___en",
    path: "/en/invite/:id()",
    meta: _91id_937Kbaa5vEkyMeta || {},
    component: () => import("/app/pages/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: "invite-id___fr",
    path: "/invite/:id()",
    meta: _91id_937Kbaa5vEkyMeta || {},
    component: () => import("/app/pages/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "logout___en",
    path: "/en/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "logout___fr",
    path: "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "management-accounts___en",
    path: "/en/management/accounts",
    component: () => import("/app/pages/management/accounts.vue").then(m => m.default || m)
  },
  {
    name: "management-accounts___fr",
    path: "/management/accounts",
    component: () => import("/app/pages/management/accounts.vue").then(m => m.default || m)
  },
  {
    name: "management-config___en",
    path: "/en/management/config",
    component: () => import("/app/pages/management/config.vue").then(m => m.default || m)
  },
  {
    name: "management-config___fr",
    path: "/management/config",
    component: () => import("/app/pages/management/config.vue").then(m => m.default || m)
  },
  {
    name: "management___en",
    path: "/en/management",
    component: () => import("/app/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: "management___fr",
    path: "/management",
    component: () => import("/app/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: "management-orders___en",
    path: "/en/management/orders",
    component: () => import("/app/pages/management/orders.vue").then(m => m.default || m)
  },
  {
    name: "management-orders___fr",
    path: "/management/orders",
    component: () => import("/app/pages/management/orders.vue").then(m => m.default || m)
  },
  {
    name: "orders-create___en",
    path: "/en/orders/create",
    component: () => import("/app/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders-create___fr",
    path: "/orders/create",
    component: () => import("/app/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders___en",
    path: "/en/orders",
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders___fr",
    path: "/orders",
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-id___en",
    path: "/en/password-reset/:id()",
    meta: _91id_93vZXVq7IOTKMeta || {},
    component: () => import("/app/pages/password-reset/[id].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-id___fr",
    path: "/password-reset/:id()",
    meta: _91id_93vZXVq7IOTKMeta || {},
    component: () => import("/app/pages/password-reset/[id].vue").then(m => m.default || m)
  },
  {
    name: "password-reset___en",
    path: "/en/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset___fr",
    path: "/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___en",
    path: "/en/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments___fr",
    path: "/payments",
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "printers___en",
    path: "/en/printers",
    component: () => import("/app/pages/printers/index.vue").then(m => m.default || m)
  },
  {
    name: "printers___fr",
    path: "/printers",
    component: () => import("/app/pages/printers/index.vue").then(m => m.default || m)
  },
  {
    name: "printers-productions___en",
    path: "/en/printers/productions",
    meta: productionsBX6pYq43xrMeta || {},
    component: () => import("/app/pages/printers/productions.vue").then(m => m.default || m)
  },
  {
    name: "printers-productions___fr",
    path: "/printers/productions",
    meta: productionsBX6pYq43xrMeta || {},
    component: () => import("/app/pages/printers/productions.vue").then(m => m.default || m)
  },
  {
    name: "printers-stocks___en",
    path: "/en/printers/stocks",
    component: () => import("/app/pages/printers/stocks.vue").then(m => m.default || m)
  },
  {
    name: "printers-stocks___fr",
    path: "/printers/stocks",
    component: () => import("/app/pages/printers/stocks.vue").then(m => m.default || m)
  },
  {
    name: "printers-transactions___en",
    path: "/en/printers/transactions",
    component: () => import("/app/pages/printers/transactions.vue").then(m => m.default || m)
  },
  {
    name: "printers-transactions___fr",
    path: "/printers/transactions",
    component: () => import("/app/pages/printers/transactions.vue").then(m => m.default || m)
  },
  {
    name: "register-id___en",
    path: "/en/register/:id()",
    meta: _91id_938d7mq4Zm2kMeta || {},
    component: () => import("/app/pages/register/[id].vue").then(m => m.default || m)
  },
  {
    name: "register-id___fr",
    path: "/register/:id()",
    meta: _91id_938d7mq4Zm2kMeta || {},
    component: () => import("/app/pages/register/[id].vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: indexhOBcdtdQ4pMeta || {},
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register___fr",
    path: "/register",
    meta: indexhOBcdtdQ4pMeta || {},
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-account___en",
    path: "/en/settings/account",
    component: () => import("/app/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-account___fr",
    path: "/settings/account",
    component: () => import("/app/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing___en",
    path: "/en/settings/billing",
    component: () => import("/app/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing___fr",
    path: "/settings/billing",
    component: () => import("/app/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-user___en",
    path: "/en/settings/user",
    component: () => import("/app/pages/settings/user.vue").then(m => m.default || m)
  },
  {
    name: "settings-user___fr",
    path: "/settings/user",
    component: () => import("/app/pages/settings/user.vue").then(m => m.default || m)
  },
  {
    name: "stocks___en",
    path: "/en/stocks",
    component: () => import("/app/pages/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: "stocks___fr",
    path: "/stocks",
    component: () => import("/app/pages/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions___en",
    path: "/en/subscriptions",
    component: () => import("/app/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions___fr",
    path: "/subscriptions",
    component: () => import("/app/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "team___en",
    path: "/en/team",
    component: () => import("/app/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: "team___fr",
    path: "/team",
    component: () => import("/app/pages/team/index.vue").then(m => m.default || m)
  }
]